import { Icon } from "../icon";
import styles from "./Footer.module.scss";

const URL_TELEGRAM = "https://telegram.me/Prodamus_connection_bot";
const URL_MAIL = "mailto: connection@prodamus.ru";
const URL_PRODAMUS = "https://prodamus.ru/";

export const Footer = (): JSX.Element => {
  const currentYear = new Date().getFullYear()?.toString();

  return (
    <div className={styles.container}>
      <div className={styles.containerButtons}>
        <a href={URL_PRODAMUS} target="_blank" rel="noreferrer">
          <Icon name="prodamus-logo-horizontal" className={styles.logoProdamus} />
        </a>
        <div className={styles.containerName}>
          <p>{`© ${currentYear}, ООО «Продамус»`}</p>
          <p>Все права защищены.</p>
        </div>
        <div className={styles.containerSupport}>
          <a
            href={URL_TELEGRAM}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.supportTelegram}
          >
            <Icon name="tg" className={styles.supportIcon} />
            Написать в телеграм
          </a>
          <a
            href={URL_MAIL}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.supportMail}
          >
            <Icon name="mail" className={styles.supportIcon} />
            Отправить письмо
          </a>
        </div>
      </div>
    </div>
  );
};
