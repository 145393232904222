import { type AxiosRequestConfig } from "axios";
import { clientApi } from "~/utils/clientApi";
import { defaultAxiosRequestConfig } from "./apiService";
import {
  GENERATE_UNIQUE_ID,
  DRAFT_PREVIEW,
  DRAFT_COMPANY_DETAILS,
  DRAFT_CONTACT_DETAILS,
  DRAFT_PAYFORM_DETAILS,
  DRAFT_ACCOUNT_DETAILS,
  DRAFT_PAYFORM_TECH_DETAILS,
  DRAFT_MARKETPLACE_DETAILS,
  DRAFT_ATTACHMENTS_DETAILS,
  DRAFT_ADDITIONAL_DETAILS,
  DRAFT_SAVE,
  TAXONOMY_PAYMENT_METHOD,
  EDUCATIONAL_PLATFORM,
  REPORT_ERROR,
  TAXONOMY_PRODUCT_TYPE,
  PERSONAL_DATA_BY_INN,
  REQUEST_CODE,
  CONFIRM_CODE,
  CHANGE_PHONE,
  DRAGT_SHORTCUT_HASH_INFO
} from "~/configs/apiUrls";
import {
  AccountDetailsFormFields,
  AdditionalDetailsFormFields,
  AttachmentsDetailsFormFields,
  ClientId,
  CompanyDetailsFormFields,
  ConfigNamesFields,
  ContactDetailsFormFields,
  MarketplaceDetailsFormFields,
  NameStepsEnum,
  NamesOfFieldEnum,
  OrgTypeEnum,
  PayformDetailsFormFields,
  PayformTechDetailsFormFields
} from "~/typings/types";

export type ResSuccess = 1 | 0;

export interface ReqPostData<T> {
  id: ClientId;
  is_presave_mode?: boolean;
  fields: T;
}

export interface ReqPostArguments<T> {
  data: ReqPostData<T>;
  config?: DefaultAxiosRequestConfig;
}

export interface ErrorFieldFromBackend {
  code: "field_validation" | "confirmation:incorrect_code" | "code_confirmation:too_many_attempts";
  message: string;
  data: {
    target_type: string;
    target: string;
  };
}

export interface ResPost {
  success: ResSuccess;
  errors?: ErrorFieldFromBackend[];
}

export type SharedDataFromBackend = Record<string, { name: string; title: string }>;

export interface DefaultAxiosRequestConfig extends AxiosRequestConfig {
  retry?: number;
  retryDelay?: number;
}

interface ApiResponseSharedDataFromBackend {
  success: ResSuccess;
  data: SharedDataFromBackend;
}

export interface ApiResponseGetUniqueClientId {
  success: ResSuccess;
  data: {
    id: ClientId;
    shortcut_hash: ClientId;
  };
}
export interface ApiResponseGetClientIdByHash {
  success: ResSuccess;
  data: {
    id: ClientId;
  };
}

export const getUniqueClientId = async (): Promise<ApiResponseGetUniqueClientId> => {
  try {
    const res = await clientApi.get(GENERATE_UNIQUE_ID);
    return res.data;
  } catch (err: any) {
    throw new Error(err);
  }
};

export const getClientIdByHash = async ({
  hash
}: {
  hash: string;
}): Promise<ApiResponseGetClientIdByHash> => {
  try {
    const res = await clientApi.get(DRAGT_SHORTCUT_HASH_INFO + `?hash=${hash}`);
    return res.data;
  } catch (err: any) {
    throw new Error(err);
  }
};

export const getPaymentMethod = async (): Promise<ApiResponseSharedDataFromBackend> => {
  try {
    const res = await clientApi.get(TAXONOMY_PAYMENT_METHOD);
    if (res?.data?.success === 0) {
      throw new Error("Error from backend, method: getPaymentMethod");
    }
    return res.data;
  } catch (err: any) {
    throw new Error(err);
  }
};

export const getProductType = async (): Promise<ApiResponseSharedDataFromBackend> => {
  try {
    const res = await clientApi.get(TAXONOMY_PRODUCT_TYPE);
    if (res?.data?.success === 0) {
      throw new Error("Error from backend, method: getProductType");
    }
    return res.data;
  } catch (err: any) {
    throw new Error(err);
  }
};

export const getEducationalPlatform = async (): Promise<ApiResponseSharedDataFromBackend> => {
  try {
    const res = await clientApi.get(EDUCATIONAL_PLATFORM);
    if (res?.data?.success === 0) {
      throw new Error("Error from backend, method: getEducationalPlatform");
    }
    return res.data;
  } catch (err: any) {
    throw new Error(err);
  }
};

export interface GetPersonalDataByInn {
  id: ClientId;
  [NamesOfFieldEnum.OrgType]: OrgTypeEnum;
  [NamesOfFieldEnum.Inn]: string;
}

export type ResGetPersonalDataByInn = {
  success: 1 | 0;
  fields: ConfigNamesFields;
};

export const getPersonalDataByInn = async (
  data: GetPersonalDataByInn
): Promise<ResGetPersonalDataByInn> => {
  try {
    const res = await clientApi.post(PERSONAL_DATA_BY_INN, data);
    return res.data;
  } catch (err: any) {
    throw new Error(err);
  }
};

interface DraftPrewievId {
  clientId: string;
}

export interface ApiResponseDraftPreviewId {
  success: 1 | 0;
  data: {
    questionnaire_id: ClientId;
    chapters: Partial<{
      [NameStepsEnum.AccountDetails]: AccountDetailsFormFields;
      [NameStepsEnum.AdditionalDetails]: AdditionalDetailsFormFields;
      [NameStepsEnum.CompanyDetails]: CompanyDetailsFormFields;
      [NameStepsEnum.ContactDetails]: ContactDetailsFormFields;
      [NameStepsEnum.MarketplaceDetails]: MarketplaceDetailsFormFields;
      [NameStepsEnum.PayformDetails]: PayformDetailsFormFields;
      [NameStepsEnum.PayformTechDetails]: PayformTechDetailsFormFields;
    }>;
    flat: FormData;
    ready_to_save: 1 | 0;
    errors: [];
    chapter_latest_int: number;
    chapter_latest: NameStepsEnum;
    shortcut_hash: string;
    should_generate_new_id?: boolean;
  };
}

export const getDraftPreviewId = async ({
  clientId
}: DraftPrewievId): Promise<ApiResponseDraftPreviewId> => {
  try {
    const res = await clientApi.get(`${DRAFT_PREVIEW}/${clientId}`);
    if (res?.data?.success === 0) {
      throw new Error("Error from backend, method: getDraftPreviewId");
    }
    return res.data;
  } catch (err: any) {
    throw new Error(err);
  }
};

export const postDraftCompanyDetails = async ({
  data,
  config
}: ReqPostArguments<CompanyDetailsFormFields>): Promise<ResPost> => {
  try {
    const res = await clientApi.post(DRAFT_COMPANY_DETAILS, data, config);
    return res.data;
  } catch (err: any) {
    throw new Error(err);
  }
};

export const postDraftContactDetails = async ({
  data,
  config
}: ReqPostArguments<ContactDetailsFormFields>): Promise<ResPost> => {
  try {
    const res = await clientApi.post(DRAFT_CONTACT_DETAILS, data, config);
    return res.data;
  } catch (err: any) {
    throw new Error(err);
  }
};

export const postDraftPayformDetails = async ({
  data,
  config
}: ReqPostArguments<PayformDetailsFormFields>): Promise<ResPost> => {
  try {
    const res = await clientApi.post(DRAFT_PAYFORM_DETAILS, data, config);
    return res.data;
  } catch (err: any) {
    throw new Error(err);
  }
};

export const postDraftAccountDetails = async ({
  data,
  config
}: ReqPostArguments<AccountDetailsFormFields>): Promise<ResPost> => {
  try {
    const res = await clientApi.post(DRAFT_ACCOUNT_DETAILS, data, config);
    return res.data;
  } catch (err: any) {
    throw new Error(err);
  }
};

export const postDraftPayformTechDetails = async ({
  data,
  config
}: ReqPostArguments<PayformTechDetailsFormFields>): Promise<ResPost> => {
  try {
    const res = await clientApi.post(DRAFT_PAYFORM_TECH_DETAILS, data, config);
    return res.data;
  } catch (err: any) {
    throw new Error(err);
  }
};

export const postDraftMarketplaceDetails = async ({
  data,
  config
}: ReqPostArguments<MarketplaceDetailsFormFields>): Promise<ResPost> => {
  try {
    const res = await clientApi.post(DRAFT_MARKETPLACE_DETAILS, data, config);
    return res.data;
  } catch (err: any) {
    throw new Error(err);
  }
};

export const postDraftAttachmentsDetails = async ({
  data,
  config
}: ReqPostArguments<AttachmentsDetailsFormFields>): Promise<ResPost> => {
  try {
    const res = await clientApi.post(DRAFT_ATTACHMENTS_DETAILS, data, config);
    return res.data;
  } catch (err: any) {
    throw new Error(err);
  }
};

export const postDraftAdditionalDetails = async ({
  data,
  config
}: ReqPostArguments<AdditionalDetailsFormFields>): Promise<ResPost> => {
  try {
    const res = await clientApi.post(DRAFT_ADDITIONAL_DETAILS, data, config);
    return res.data;
  } catch (err: any) {
    throw new Error(err);
  }
};

interface PostDraftSubmitSave {
  data: {
    id: ClientId;
  };
  config?: AxiosRequestConfig;
}

export const postDraftSubmitSave = async ({ data }: PostDraftSubmitSave): Promise<ResPost> => {
  try {
    const res = await clientApi.post(DRAFT_SAVE, data, defaultAxiosRequestConfig);
    if (res?.data?.success === 0) {
      throw new Error("Error from backend, method: postDraftSubmitSave");
    }
    return res.data;
  } catch (err: any) {
    throw new Error(err);
  }
};

interface PostDraftReportError {
  data: {
    id: ClientId;
    chapter_name: NameStepsEnum;
    other: {
      user_agent: string;
      fields: FormData;
      traceError?: string | object;
    };
  };
  config?: AxiosRequestConfig;
}

export const postDraftReportError = async ({
  data,
  config
}: PostDraftReportError): Promise<ResPost> => {
  try {
    const res = await clientApi.post(REPORT_ERROR, data, config);
    if (res?.data?.success === 0) {
      throw new Error("Error from backend, method: postDraftReportError");
    }
    return res.data;
  } catch (err: any) {
    throw new Error(err);
  }
};

type PostRequestCodeRequest = {
  data: {
    id: ClientId;
  };
  config?: AxiosRequestConfig;
};

type PostRequestCodeResponse = {
  success: ResSuccess;
  data?: {
    code_uuid: string;
  };
  errors?: ErrorFieldFromBackend[];
};

export const postRequestCode = async ({
  data,
  config
}: PostRequestCodeRequest): Promise<PostRequestCodeResponse> => {
  try {
    const res = await clientApi.post(REQUEST_CODE, data, config);
    return res.data;
  } catch (error: unknown) {
    throw error;
  }
};

type PostConfirmCodeRequest = {
  data: {
    code_uuid: string;
    code: string;
  };
  config?: AxiosRequestConfig;
};

type PostConfirmCodeResponse = {
  success: ResSuccess;
  errors?: ErrorFieldFromBackend[];
};

export const postConfirmCode = async ({
  data,
  config
}: PostConfirmCodeRequest): Promise<PostConfirmCodeResponse> => {
  try {
    const res = await clientApi.post(CONFIRM_CODE, data, config);
    if (res?.data?.success === 0) {
      throw new Error(res.data.errors?.[0].message);
    }
    return res.data;
  } catch (error: any) {
    //TODO: fixed any types
    throw error?.response?.data?.errors?.[0];
  }
};

type PostChangePhoneRequest = {
  data: {
    id: ClientId;
    phone_old: string;
    phone_new: string;
  };
  config?: AxiosRequestConfig;
};

export const postChangePhone = async ({
  data,
  config
}: PostChangePhoneRequest): Promise<ResPost> => {
  try {
    const res = await clientApi.post(CHANGE_PHONE, data, config);
    return res.data;
  } catch (error: any) {
    // throw error;
    throw error?.response?.data?.error?.[0];
  }
};
