import { Spin } from "antd";
import cn from "classnames";
import { LoadingOutlined } from "@ant-design/icons";
import { StatusConfirmationCode } from "~/typings/types";
import {
  INITIAL_REQUEST_CODE,
  PENDING_REQUEST_CODE,
  RESEND_REQUEST_CODE,
  SEND_WITHOUT_REQUEST_CODE
} from "~/configs/consts";
import { COUNT_REQUEST_CODE } from "../useConfirmationCode";

import styles from "./ResendCode.module.scss";

const antIcon = <LoadingOutlined style={{ fontSize: 18, color: "#008FCA" }} spin />;

type Props = {
  handleGetRequestCode: ({ status }: { status: StatusConfirmationCode }) => Promise<void>;
  isRunningTimer: boolean;
  secondsTimer: number;
  countTryStartTimer: number;
  isLoadingGetRequestCode: boolean;
  statusConfirmationCode: StatusConfirmationCode;
};

export const ResendCode = ({
  secondsTimer,
  countTryStartTimer,
  handleGetRequestCode,
  isLoadingGetRequestCode,
  statusConfirmationCode
}: Props) => {
  const handleResendCode = () => {
    if (COUNT_REQUEST_CODE === countTryStartTimer) {
      handleGetRequestCode({ status: SEND_WITHOUT_REQUEST_CODE });
    } else {
      handleGetRequestCode({ status: PENDING_REQUEST_CODE });
    }
  };

  const RunnigTimerComponent = () => (
    <>
      <span className={styles.text}>Получить новый код можно через:</span>
      <span
        className={cn(styles.text, styles.position)}
        style={{ color: "#000" }}
      >{`00:${secondsTimer}`}</span>
    </>
  );

  const DefaultComponent = () => (
    <p className={styles.text}>Запросите код, нажав кнопку "Получить код"</p>
  );

  const ResendCodeComponent = () => (
    <>
      <>
        <p className={styles.text}>Время ожидания истекло.</p>
        <p className={styles.text}>Запросите новый код.</p>
      </>
      {isLoadingGetRequestCode ? (
        <Spin indicator={antIcon} className={cn(styles.spin, styles.position)} />
      ) : (
        <button
          type="button"
          className={cn(styles.button, styles.position)}
          onClick={handleResendCode}
        >
          Получить новый код
        </button>
      )}
    </>
  );

  const WithoutCodeComponent = () => (
    <div className={styles.withoutCodeComponent}>
      <p className={styles.text}>Время ожидания и количество попыток закончились.</p>
      <p className={styles.text}>
        Если код так и не пришел, отправьте анкету без подтверждения номера телефона.
      </p>
    </div>
  );

  const mapComponents: any = {
    [INITIAL_REQUEST_CODE]: <DefaultComponent />,
    [PENDING_REQUEST_CODE]: <RunnigTimerComponent />,
    [RESEND_REQUEST_CODE]: <ResendCodeComponent />,
    [SEND_WITHOUT_REQUEST_CODE]: <WithoutCodeComponent />
  };

  return <div className={styles.container}>{mapComponents[statusConfirmationCode]}</div>;
};
