// @ts-nocheck
import { Field, FormSpy } from "react-final-form";
import { Divider } from "antd";
import { RadioField, CheckboxField, TitlePage, InputField } from "~/components";
import { composeValidators, disabledValidate, required, testRegExp } from "~/utils/validate";
import { Wizard, FormField } from "~/pages/main/steps/shared";
import { NamesOfFieldEnum } from "~/typings/types";
import { configTitleFieldsOfCompany } from "../configTitleFields";

import styles from "../../shared/Shared.module.scss";
import { URL_TARIF } from "../../shared/wizard";

export const PayformTechDetails = ({
  paymentMethod,
  educationalPlatform,
  productType,
  typeRequestCheckPayformName,
  callbackCheckPayformName
}: any) => {
  return (
    <Wizard.Page>
      <TitlePage
        name="Укажите данные для технической настройки вашей платёжной страницы в системе Prodamus"
        number="6"
      />
      <Divider style={{ margin: 0 }} />
      <div className={styles.container}>
        {productType && Object.keys(productType).length && (
          <FormField
            titleField={configTitleFieldsOfCompany[NamesOfFieldEnum.ProductType]}
            name={NamesOfFieldEnum.ProductType}
            tooltip="Можно выбрать только один вариант. Если и то и другое подходит, выберите то, на что приходится больше оборота"
            isColon={false}
            required
          >
            <div className={styles.containerRadio}>
              {Object.values(productType).map(({ name, title }) => (
                <Field
                  key={name}
                  name={NamesOfFieldEnum.ProductType}
                  type="radio"
                  value={name}
                  component={props => <RadioField orderNumber="3" {...props} />}
                  validate={disabledValidate(composeValidators(required()))}
                >
                  {title}
                </Field>
              ))}
            </div>
          </FormField>
        )}

        <FormSpy subscription={{ values: true }}>
          {propsSpy => (
            <>
              {propsSpy.values[NamesOfFieldEnum.ProductType] === "education" && (
                <FormField
                  titleField={configTitleFieldsOfCompany[NamesOfFieldEnum.IsHaveEducationalLicense]}
                  name={NamesOfFieldEnum.IsHaveEducationalLicense}
                  isColon={false}
                  required
                  styleContainerTitleField={{ width: "96%" }}
                >
                  <div className={styles.containerRadio}>
                    <Field
                      name={NamesOfFieldEnum.IsHaveEducationalLicense}
                      type="radio"
                      value="yes"
                      component={props => <RadioField orderNumber="1" {...props} />}
                      validate={disabledValidate(composeValidators(required()))}
                    >
                      Да
                    </Field>
                    <Field
                      name={NamesOfFieldEnum.IsHaveEducationalLicense}
                      type="radio"
                      value="no"
                      component={props => <RadioField orderNumber="1" {...props} />}
                      validate={disabledValidate(composeValidators(required()))}
                    >
                      Нет
                    </Field>
                  </div>
                </FormField>
              )}
            </>
          )}
        </FormSpy>

        <FormField
          titleField={configTitleFieldsOfCompany[NamesOfFieldEnum.PaymentNotificationEmail]}
          name={NamesOfFieldEnum.PaymentNotificationEmail}
          tooltip="На него будут приходить уведомления от Prodamus, в том числе о поступивших платежах"
          required
          validate={composeValidators(
            required(),
            testRegExp(
              /^\s*\S+@\S+\.\S+\s*$/,
              "Пожалуйста, укажите корректный адрес электронной почты"
            )
          )}
          orderNumber="2"
          triggerAutofill={[NamesOfFieldEnum.PaymentNotificationEmail, NamesOfFieldEnum.CrmEmail]}
        />

        <FormField
          titleField={configTitleFieldsOfCompany[NamesOfFieldEnum.ProductName]}
          name={NamesOfFieldEnum.ProductName}
          required
          tooltip="Например: доступ к обучающим материалам, косметические наборы"
          validate={composeValidators(required())}
          orderNumber="4"
        />

        <FormField
          titleField={configTitleFieldsOfCompany[NamesOfFieldEnum.PayformName]}
          name={NamesOfFieldEnum.PayformName}
          required
          addonBefore="https://"
          addonAfter=".payform.ru"
          tooltip="Название, которое вы придумаете, будут видеть покупатели. Например, наша платёжная страница называется prodamus.payform.ru"
          validate={composeValidators(
            required(),
            testRegExp(
              /^[a-zA-Z0-9-]{1,25}$/g,
              'Только англ. буквы, цифры и знак "-". Не более 25 символов'
            )
          )}
          orderNumber="5"
          typeRequestCheckPayformName={typeRequestCheckPayformName}
          onBlur={callbackCheckPayformName}
        />

        <FormField
          titleField={
            configTitleFieldsOfCompany[NamesOfFieldEnum.PaymentMethodProdamusSubscription]
          }
          name={NamesOfFieldEnum.PaymentMethodProdamusSubscription}
          required
          tooltip="Для безбумажного подписания необходимо провести оплату с р/с ИП или ЮЛ, с которым заключается договор. Для ускорения подключения направить платежное поручение, т.к. оплата по счету может поступить в течение трех дней."
        >
          <div>
            <Field
              name={NamesOfFieldEnum.PaymentMethodProdamusSubscription}
              type="radio"
              value="settlement_account"
              component={props => <RadioField orderNumber="6" {...props} />}
              validate={disabledValidate(composeValidators(required()))}
            >
              Оплатить с расчетного счета ИП или ЮЛ без ручного подписания заявления на подключение
            </Field>
            <Field
              name={NamesOfFieldEnum.PaymentMethodProdamusSubscription}
              type="radio"
              value="other"
              component={props => <RadioField orderNumber="6" {...props} />}
              validate={disabledValidate(composeValidators(required()))}
            >
              Оплатить любым способом (карта, СБП) с подписанием заявления
            </Field>
          </div>
        </FormField>

        <FormField
          titleField={configTitleFieldsOfCompany[NamesOfFieldEnum.PayformPaymentMethods]}
          name={NamesOfFieldEnum.PayformPaymentMethods}
          required
          isColon={false}
        >
          <div className={styles.containerPaymentMethods}>
            {paymentMethod &&
              Object.values(paymentMethod).map((option: any) => (
                <CheckboxField
                  key={option?.name}
                  fieldName={NamesOfFieldEnum.PayformPaymentMethods}
                  validate={disabledValidate(composeValidators(required()))}
                  orderNumber="10"
                  {...option}
                />
              ))}
            <p className={styles.descriptionPaymentMethods}>
              Подробное описание вариантов оплаты с комиссиями находится в{" "}
              <a href={URL_TARIF} target="_blank" className={styles.textBlueLink}>
                Тарифах
              </a>
            </p>
          </div>
        </FormField>
      </div>
    </Wizard.Page>
  );
};
