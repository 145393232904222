import { useState, useCallback } from "react";
import {
  GetPersonalDataByInn,
  ResGetPersonalDataByInn,
  getPersonalDataByInn
} from "~/api/apiMethods";

export function useFetchPersonalDataByInn({ id, org_type, inn }: GetPersonalDataByInn) {
  const [data, setData] = useState<ResGetPersonalDataByInn | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchPersonalDataByInn = useCallback(async () => {
    try {
      setIsLoading(true);
      setData(null);
      setError(null);

      const result = await getPersonalDataByInn({
        id,
        org_type,
        inn
      });
      await setData(result);
    } catch (error: any) {
      setError("Метод: useFetchPersonalDataByInn вернул ошибку.");
    } finally {
      setIsLoading(false);
    }
  }, [id, org_type, inn]);

  return { fetchPersonalDataByInn, data, isLoading, error };
}
