import { useFormState, useForm } from "react-final-form";

import styles from "./AutofillFieldButton.module.scss";

interface Props {
  // triggerAutofill[0] - поле для которого отрисовываем кнопку автозаполнения из значения поля triggerAutofill[1]
  triggerAutofill?: string[];
  // triggerAutofillForAddressFiasId - массив для полей адреса, которые надо заполнить при нажатии на кнопку
  triggerAutofillForAddressFiasId?: string[];
}

export const AutofillFieldButton = ({
  triggerAutofill,
  triggerAutofillForAddressFiasId
}: Props) => {
  if (!triggerAutofill || triggerAutofill?.length !== 2) {
    return null;
  }

  const form = useForm();
  const { values } = useFormState();

  const autofillFieldName = triggerAutofill[0]; // Поле которое надо заполнить при нажатии на кнопку
  const triggerFieldName = triggerAutofill[1]; // Поле из которого берем значение для автозаполнения
  const valueTrigger = values[triggerFieldName];
  const isShowButton =
    valueTrigger && values[autofillFieldName] !== valueTrigger && !values[autofillFieldName];

  const handleAutofillClick = () => {
    form.change(autofillFieldName, valueTrigger);
    if (triggerAutofillForAddressFiasId && triggerAutofillForAddressFiasId.length === 2) {
      form.change(triggerAutofillForAddressFiasId[0], values[triggerAutofillForAddressFiasId[1]]);
    }
  };

  return (
    isShowButton && (
      <button onClick={handleAutofillClick} className={styles.button}>
        {valueTrigger}
      </button>
    )
  );
};
