// @ts-nocheck
import { Field } from "react-final-form";

import styles from "./ErrorField.module.scss";

export const ErrorField = ({ name, renderError }) => (
  <Field
    name={name}
    subscription={{ submitFailed: true, error: true, submitError: true }}
    render={({ meta: { submitFailed, error, submitError } }) => {
      const isError = ((error || submitError) && submitFailed) || (renderError && renderError.has);
      return (
        <div className={styles.container}>
          {isError && (
            <p
              className={styles.text}
              style={{ color: renderError?.color }}
              dangerouslySetInnerHTML={{
                __html: (renderError?.message || error || submitError).replace(/\n/g, "</br></br>")
              }}
            />
          )}
        </div>
      );
    }}
  />
);
