export function formatPhoneNumberRu(phoneNumber: string): string {
  if (
    (phoneNumber?.length && phoneNumber?.substring(0, 1) === "7") ||
    phoneNumber?.substring(0, 2) === "+7"
  ) {
    const digits = phoneNumber.replace(/\D/g, "");

    const formattedNumber = `+${digits.substring(0, 1)} (${digits.substring(
      1,
      4
    )}) ${digits.substring(4, 7)}-${digits.substring(7, 9)}-${digits.substring(9, 11)}`;

    return formattedNumber;
  } else {
    return phoneNumber;
  }
}
