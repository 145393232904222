import { REACT_APP_FEATURE_FLAG } from "~/configs/consts";
import { mergeObjectsWithTrueValues } from "./mergeObjectsWithTrueValues";

export type GetEnabledFlags = () => string[]

export const getEnabledFlags: GetEnabledFlags = (() => {
	let cache: any = null;

	return () => {
		if (cache !== null) {
			return cache;
		}

		const flagsFromEnvFile = JSON.parse(import.meta.env.VITE_APP_FEATURE_FLAG || "{}");
		const flagsFromLocalStorage = JSON.parse(localStorage.getItem(REACT_APP_FEATURE_FLAG) || "{}");

		const enabledFlags = mergeObjectsWithTrueValues(flagsFromLocalStorage, flagsFromEnvFile);

		cache = enabledFlags;

		return enabledFlags;
	};
})();