// @ts-nocheck
import { Field } from "react-final-form";
import cn from "classnames";

import styles from "./CheckboxField.module.scss";

export const Checkbox = ({ disabled, styleIndicatorProps, checked, onChange, ...props }: any) => {
  const handleChange = () => {
    onChange(!checked);
  };

  return (
    <label
      className={cn(styles.checkbox, {
        [styles.disabledLabel]: disabled
      })}
    >
      <input
        {...props.input}
        disabled={disabled}
        checked={checked || props?.input?.checked}
        onChange={onChange ? handleChange : props.input.onChange}
      />
      <span className={styles.indicator} style={styleIndicatorProps}></span>
      {props.children}
    </label>
  );
};

export const CheckboxField = ({
  fieldName,
  name,
  title,
  validate,
  orderNumber,
  disabled,
  styleIndicatorProps,
  checked,
  onChange
}: any) => {
  return (
    <Field
      name={fieldName}
      type="checkbox"
      value={name}
      id={name}
      component={Checkbox}
      children={title}
      validate={validate}
      orderNumber={orderNumber}
      disabled={disabled}
      styleIndicatorProps={styleIndicatorProps}
      checked={checked}
      onChange={onChange}
    />
  );
};
