interface Props<T> {
  object: any;
  values: (keyof T | undefined)[];
}

export function getValuesFromObject<T>({ object, values }: Props<T>): T {
  const result = {} as T;

  values.forEach(value => {
    if (value && object && object.hasOwnProperty(value)) {
      result[value] = object[value];
    }
  });

  return result;
}
