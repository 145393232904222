import { useCallback, useEffect, useState } from "react";
import { clientApi } from "~/utils";
import { postDraftAttachmentsDetails } from "~/api/apiMethods";
import { AttachmentsDetailsError, ClientId, NamesOfFieldEnum, OrgTypeEnum } from "~/typings/types";

import { useUploadLogoFile, useUploadPassportFile } from ".";

// TODO: need refactor
// Функия для валидации которая приходит с бека, если приходит ошибки то показываем ошибку под Файламм
const getErrorAttachmentDetails = (dataError: any, setLogoError: any, setPassportError: any) => {
  if (!dataError || !Array.from(dataError) || !dataError.length) {
    return;
  }

  dataError.forEach((error: any) => {
    const fieldName = error.data.target;
    if (fieldName === NamesOfFieldEnum.LogoFileId) {
      setLogoError({
        has: 1,
        message: error.message
      });
    }
    if (fieldName === NamesOfFieldEnum.PassportFileId) {
      setPassportError({
        has: 1,
        message: error.message
      });
    }
  });
};

type UseAttachmentsDetails = {
  clientId: ClientId;
  orgType: OrgTypeEnum;
  initialLogoFileId: number[];
  initialPassportFileId: number[];
  isAcceptPoliticsDefault?: boolean;
};

export const useAttachmentsDetails = ({
  clientId,
  orgType,
  initialLogoFileId,
  initialPassportFileId,
  isAcceptPoliticsDefault = true,
}: UseAttachmentsDetails) => {
  /** Запрашиваем сохранненые файлы ранее при маунте компонента и получаем id файлов */
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    Promise.allSettled([
      initialLogoFileId[0] &&
      clientApi
        .get(`/api/questionnaire/v1/helper/file/?file_id=${initialLogoFileId[0]}`)
        .then(res => {
          setLogoFiles(prev => [...prev, new File([], res.data.data.filename_original)]);
          setLogoFileId(prev => [...prev, res.data.data.file_id]);
        }),
      initialPassportFileId[0] &&
      clientApi
        .get(`/api/questionnaire/v1/helper/file/?file_id=${initialPassportFileId[0]}`)
        .then(res => {
          setPassportFiles(prev => [...prev, new File([], res.data.data.filename_original)]);
          setPassportFileId(prev => [...prev, res.data.data.file_id]);
        }),
      initialPassportFileId[1] &&
      clientApi
        .get(`/api/questionnaire/v1/helper/file/?file_id=${initialPassportFileId[1]}`)
        .then(res => {
          setPassportFiles(prev => [...prev, new File([], res.data.data.filename_original)]);
          setPassportFileId(prev => [...prev, res.data.data.file_id]);
        })
    ]).finally(() => setIsLoading(false));
  }, []);
  /** ------ end ------ */

  /** ------ Отправляем файл на сервер после загрузки <input type="file" /> ------ */
  const [logoFiles, setLogoFiles] = useState<File[]>([]);
  const [passportFiles, setPassportFiles] = useState<File[]>([]);
  const [logoError, setLogoError] = useState<AttachmentsDetailsError>(null);
  const [passportError, setPassportError] = useState<AttachmentsDetailsError>(null);
  const [isFirstLoadingFile, setIsFirstLoadingFile] = useState(false); // Флаг который изменятся когда происходит хоть одно с действием с файлами, удаление/добавление

  const isErrorAttachmentsDetails = Boolean(logoError) || Boolean(passportError);

  const {
    filesId: logoFileId,
    setFilesId: setLogoFileId,
    isLoading: isLoadingUploadLogoFile
  } = useUploadLogoFile({ logoFiles, setLogoError, setIsFirstLoadingFile });

  const {
    filesId: passportFileId,
    setFilesId: setPassportFileId,
    isLoading: isLoadingUploadPassportFile
  } = useUploadPassportFile({ passportFiles, setPassportError, setIsFirstLoadingFile });
  /** ------ end ------ */

  /** ------ Сохраняем в черновик отправленные файла по id ------ */
  const [isLoadingAttachmentsDetails, setIsLoadingAttachmentsDetails] = useState(false);

  const handleRequestAttachmentsDetails = useCallback(
    async ({ orgType }: { orgType: OrgTypeEnum }) => {
      try {
        setIsLoadingAttachmentsDetails(true);
        const responsePostDraftAttachmentsDetails = await postDraftAttachmentsDetails({
          data: {
            id: clientId,
            is_presave_mode: false,
            fields: {
              ...(logoFileId?.length && { [NamesOfFieldEnum.LogoFileId]: logoFileId }),
              ...(passportFileId?.length && { [NamesOfFieldEnum.PassportFileId]: passportFileId }),
              [NamesOfFieldEnum.OrgType]: orgType,
              [NamesOfFieldEnum.IsOffertAccepted]: 1
            }
          }
        });
        setIsLoadingAttachmentsDetails(false);
        if (
          responsePostDraftAttachmentsDetails?.success === 0 &&
          responsePostDraftAttachmentsDetails?.errors &&
          responsePostDraftAttachmentsDetails.errors.length > 0
        ) {
          getErrorAttachmentDetails(
            responsePostDraftAttachmentsDetails.errors,
            setLogoError,
            setPassportError
          );
          return 0; // возвращаем статус ошибки валидации
        }
      } catch (error) {
        throw error;
      }
    },
    [logoFileId, passportFileId]
  );
  /** ------ end ------ */


  useEffect(() => {
    if (isFirstLoadingFile) {
      postDraftAttachmentsDetails({
        data: {
          id: clientId,
          is_presave_mode: true,
          fields: {
            [NamesOfFieldEnum.LogoFileId]: logoFileId,
            [NamesOfFieldEnum.PassportFileId]: passportFileId,
            [NamesOfFieldEnum.OrgType]: orgType,
            [NamesOfFieldEnum.IsOffertAccepted]: 1,
          }
        }
      });
    }
  }, [logoFileId, passportFileId])

  const [isAcceptPolitics, setIsAcceptPolitics] = useState(isAcceptPoliticsDefault);
  const [acceptPoliticsError, setAcceptPoliticsError] = useState<AttachmentsDetailsError>(null);

  return {
    isLoadingMount: isLoading,
    isLoadingLogo: isLoadingUploadLogoFile,
    isLoadingPassport: isLoadingUploadPassportFile,
    isLoadingAttachmentsDetails,
    isErrorAttachmentsDetails,
    logoFileId,
    logoFiles,
    setLogoFileId,
    setLogoFiles,
    passportFileId,
    passportFiles,
    setPassportFileId,
    setPassportFiles,
    handleRequestAttachmentsDetails,
    logoError,
    passportError,
    setLogoError,
    setPassportError,
    isAcceptPolitics,
    setIsAcceptPolitics,
    acceptPoliticsError,
    setAcceptPoliticsError,
    setIsFirstLoadingFile
  };
};
