import { Dispatch, SetStateAction, useEffect } from "react";
import { getCurrentStep } from "~/utils";
import { getDraftPreviewId, getUniqueClientId, getClientIdByHash } from "~/api/apiMethods";
import { KEY_CLIENT_ID, KEY_CLIENT_HASH } from "~/configs/consts";
import { NameStepsEnum, NamesOfFieldEnum, OrgTypeEnum } from "~/typings/types";
import { NavigateFunction, useNavigate } from "react-router-dom";

/** Генерируем новый clientId:
 * Сохраняем clientId в LocalStorage
 */
const generateNewClientId = async () => {
  try {
    const result = await getUniqueClientId();
    const clientId = result?.data?.id;

    localStorage.setItem(KEY_CLIENT_ID, JSON.stringify(clientId));
  } catch (error) {
    console.log("Ошибка при генерации нового clientId", error);
  }
};

type GetDraftDataByClientId = {
  clientId: string;
  setOrgType: Dispatch<SetStateAction<OrgTypeEnum>>;
  setIsSubmit: Dispatch<SetStateAction<boolean>>;
  setFormData: Dispatch<SetStateAction<any>>;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  isReturnedFirstStep: boolean;
};

/**
 * Запрашиваем данные для анкеты по clientId
 * Добавляем в форму уже заполненные данные анкеты
 * Переводим клиента на нужный step
 */
const getDraftDataByClientId = async ({
  clientId,
  setOrgType,
  setIsSubmit,
  setFormData,
  setCurrentStep,
  isReturnedFirstStep
}: GetDraftDataByClientId) => {
  getDraftPreviewId({ clientId }).then(res => {
    // Обработку флага если анкета была уже создана то генерить новый clientId и hash
    if (res.data?.should_generate_new_id) {
      generateNewClientId();
      return;
    }
    localStorage.setItem(KEY_CLIENT_ID, JSON.stringify(clientId));
    setFormData(res?.data);

    if (res?.data?.chapters?.[NameStepsEnum.AccountDetails]?.[NamesOfFieldEnum.OrgType]) {
      setOrgType(res?.data?.chapters?.[NameStepsEnum.AccountDetails][NamesOfFieldEnum.OrgType]);
      if (!isReturnedFirstStep) {
        const currentStep = getCurrentStep({ chapters: res?.data?.chapters });
        setCurrentStep(currentStep);
      }
      setIsSubmit(true);
    }
  });
};

type GetDraftByHash = {
  currentHash: string;
  navigate: NavigateFunction;
  setOrgType: Dispatch<SetStateAction<OrgTypeEnum>>;
  setIsSubmit: Dispatch<SetStateAction<boolean>>;
  setFormData: Dispatch<SetStateAction<any>>;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  isReturnedFirstStep: boolean;
};

const getDraftByHash = async ({
  currentHash,
  setOrgType,
  setIsSubmit,
  setFormData,
  setCurrentStep,
  isReturnedFirstStep
}: GetDraftByHash) => {
  try {
    /** Запрашиваем clientId по параметру currentHash  */
    const clientIdByHash = await getClientIdByHash({ hash: currentHash });

    if (clientIdByHash.success) {
      const currentClientId = clientIdByHash.data.id;

      /**
       * Запрашиваем данные для анкеты по clientId
       */
      getDraftDataByClientId({
        clientId: currentClientId,
        setOrgType,
        setIsSubmit,
        setFormData,
        setCurrentStep,
        isReturnedFirstStep
      });
    } else {
      /** По hash не нашли clientId, генерируем новый */
      generateNewClientId();
    }
  } catch (error) {
    console.log("Ошибка при попытке получить clientId по hash", error);
  }
};

type UseGetDraftPreviewId = {
  setOrgType: Dispatch<SetStateAction<OrgTypeEnum>>;
  setIsSubmit: Dispatch<SetStateAction<boolean>>;
  setFormData: Dispatch<SetStateAction<any>>;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  isReturnedFirstStep: boolean;
  setIsReturnedFirstStep: Dispatch<SetStateAction<boolean>>;
};

/** Получение/создание уникального clientId для Draft, получение DraftData по clientId   */
export function useGetDraftPreviewId({
  setFormData,
  setOrgType,
  setCurrentStep,
  setIsSubmit,
  setIsReturnedFirstStep,
  isReturnedFirstStep
}: UseGetDraftPreviewId) {
  const navigate = useNavigate();

  const mainCheck = async () => {
    // Проверяем есть ли hash в URL
    const currentHash = new URLSearchParams(location.search).get(KEY_CLIENT_HASH);

    if (!currentHash) {
      // Проверяем есть сохраненный clientId в LocalStorage
      const itemClientId = localStorage.getItem(KEY_CLIENT_ID);
      const clientId = itemClientId ? JSON.parse(itemClientId) : null;

      if (clientId) {
        /** Если нет hash в URL, но есть clientId в LocalStorage то:
         * Запрашиваем данные для анкеты по clientId
         */
        getDraftDataByClientId({
          clientId,
          setOrgType,
          setIsSubmit,
          setFormData,
          setCurrentStep,
          isReturnedFirstStep
        });
        return;
      } else {
        /** Если нет hash в URL и нет clientId в LocalStorage то:
         * Генерируем новый clientId
         */
        generateNewClientId();
      }
    } else {
      // В URL пришел hash

      getDraftByHash({
        currentHash,
        navigate,
        setOrgType,
        setIsSubmit,
        setFormData,
        setCurrentStep,
        isReturnedFirstStep
      });
    }
  };

  useEffect(() => {
    mainCheck();
  }, []);

  useEffect(() => {
    const itemClientId = localStorage.getItem(KEY_CLIENT_ID);
    const clientId = itemClientId ? JSON.parse(itemClientId) : null;
    /** Когда заполнили некоторые шаги и вернулись на самые первый шаг, надо перезапросить данные с бека для формы */
    if (clientId && isReturnedFirstStep) {
      getDraftPreviewId({ clientId }).then(res => {
        setFormData(res?.data);
        setIsReturnedFirstStep(false);
      });
    }
  }, [isReturnedFirstStep]);

  return {};
}
