import { type FC } from "react";
import cn from "classnames";
import { CustomButton } from "~/components";
import { ConfigNamesFields, NamesOfFieldEnum, TypeBankInfo } from "~/typings/types";

import styles from "./ModalContent.module.scss";

interface Props {
  fields: TypeBankInfo;
  configNameFields: ConfigNamesFields;
  handleClickSuccess: () => void;
  handleClickReject: () => void;
}

export const ModalContent: FC<Props> = ({
  fields,
  handleClickSuccess,
  handleClickReject,
  configNameFields
}) => {
  if (typeof fields === "string") {
    return (
      <div className={styles.containerError}>
        <p className={styles.textError}>{fields}</p>
        <div className={styles.footerError}>
          <CustomButton
            name="Понятно"
            type="button"
            onClick={handleClickReject}
            className={cn(styles.button)}
          />
        </div>
      </div>
    );
  }

  const renderTitle = () => {
    return (
      <div>
        <h4 className={styles.title}>{`Проверьте данные`}</h4>
        <div>
          <p className={styles.titleDesc}>Если все верно, мы автоматически заполним поля анкеты.</p>
          <p className={styles.titleDesc} style={{ marginTop: 4 }}>
            Если обнаружили ошибку — нажмите «Нет» и заполните поля вручную.
          </p>
        </div>
      </div>
    );
  };

  const renderContent = (fields: ConfigNamesFields) => {
    return (Object.entries(fields) as [NamesOfFieldEnum, string][]).map(
      ([nameField, valueField]) => {
        if (nameField === "name" || !(nameField in configNameFields)) {
          return;
        }
        return (
          <div key={nameField} className={styles.fieldsRow}>
            <p className={styles.firstColumn}>{configNameFields[nameField]}</p>
            <p className={styles.secondColumn}>{valueField}</p>
          </div>
        );
      }
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div>{renderTitle()}</div>
        <div>{renderContent(fields)}</div>
      </div>
      <div className={styles.footer}>
        <CustomButton
          name="Да, правильно"
          type="button"
          onClick={handleClickSuccess}
          className={cn(styles.button)}
        />
        <CustomButton
          name="Нет"
          type="button"
          onClick={handleClickReject}
          className={cn(styles.button, styles.buttonBack)}
        />
      </div>
    </div>
  );
};
