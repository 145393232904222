import { useEffect, useState } from "react";
import {
  getPaymentMethod,
  getEducationalPlatform,
  getProductType,
  SharedDataFromBackend
} from "~/api/apiMethods";

export function useSharedDataFromBackend() {
  const [paymentMethod, setPaymentMethod] = useState<SharedDataFromBackend>();
  const [educationalPlatform, setEducationalPlatform] = useState<SharedDataFromBackend>();
  const [productType, setProductType] = useState<SharedDataFromBackend>();

  useEffect(() => {
    getPaymentMethod().then(res => {
      setPaymentMethod(res?.data);
    });
    getEducationalPlatform().then(res => {
      setEducationalPlatform(res?.data);
    });
    getProductType().then(res => {
      setProductType(res?.data);
    });
  }, []);

  return { paymentMethod, educationalPlatform, productType };
}
