import { Icon } from "../icon";
import styles from "./Header.module.scss";

export const Header = (): JSX.Element => {
  return (
    <div className={styles.container}>
      <Icon name={"prodamus-logo"} className={styles.logo} />
    </div>
  );
};
