// @ts-nocheck

export const composeValidators =
  (...validators) =>
  value =>
    validators.reduce((error, validator) => error || validator(value), undefined);

export const required =
  (isRadio = false) =>
  value => {
    if (isRadio) {
      if (value === false || value === true) {
        return undefined;
      } else {
        return "Обязательно для заполнения";
      }
    }

    if (Array.isArray(value) && value.length === 0) {
      return "Обязательно для заполнения";
    }

    return value ? undefined : "Обязательно для заполнения";
  };

export const testRegExp = (regExp, messageError) => value => {
  return new RegExp(regExp).test(value) || !value ? undefined : messageError;
};

export const notEmptyValue = () => value => {
  return value && value.trim() === "" ? "Обязательно для заполнения" : undefined;
};

// Если в локал сторадже сохранен флан disabledValidate=true, то валидация у полей на фронте отключается
export const disabledValidate = fn => {
  if (window.localStorage.getItem("disabledValidate") === "true") {
    return false;
  }
  return fn;
};
