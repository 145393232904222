import { NameStepsEnum } from "~/typings/types";

// Порядок шагов в форме
export const configSteps: Array<NameStepsEnum | string> = [
  "default",
  NameStepsEnum.AccountDetails,
  NameStepsEnum.CompanyDetails,
  NameStepsEnum.ContactDetails,
  NameStepsEnum.PayformDetails,
  NameStepsEnum.PayformTechDetails,
  NameStepsEnum.MarketplaceDetails,
  NameStepsEnum.AdditionalDetails,
  NameStepsEnum.AttachmentsDetails
];
