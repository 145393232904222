import { configSteps } from "~/configs/steps";
import { NameStepsEnum, NamesOfFieldEnum } from "~/typings/types";

type Chapters = Record<string, any>;

/*
  Функция позволяет получить степ на котором остановился пользователь
  И возвращает степ на который нужно его вернуть при повторном входе
*/
export const getCurrentStep = ({ chapters }: { chapters: Chapters }): number => {
  let currentStep = 0;
  if (!configSteps?.length) {
    return currentStep;
  }
  for (let i = configSteps.length - 1; i > 0; i--) {
    const step = configSteps[i];
    if (
      !Array.isArray(chapters[step]) &&
      chapters[step] &&
      Object.keys(chapters[step]).length !== 0
    ) {
      currentStep = i;
      break;
    }
  }

  // Если все шаги анкеты заполнены, возвращать клиента всегда на страницу подтверждения по смс
  // if (chapters?.[NameStepsEnum.AttachmentsDetails]?.[NamesOfFieldEnum.IsOffertAccepted] === 1) {
  //   return currentStep + 1;
  // }

  return currentStep;
};
