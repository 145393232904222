import { useEffect } from "react";
import { BUTTON_NEXT_ID, ERROR_FIELD_ } from "~/configs/consts";

interface ClickedElement extends HTMLElement {
  id: string;
}

/**
 * При клике на кнопку "Продолжить",
 * Если упала валидация в одном из полей формы, то пользователя перенаправляем на это поле,
 * Устанавливаем фокус и скролим к нужному полю с ошибкой
 */
export function useScrollToErrorElement() {
  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;
    let timeoutScroll: ReturnType<typeof setTimeout>;
    const handleClick = (event: MouseEvent) => {
      const buttonId = BUTTON_NEXT_ID;
      const clickedElement = event.target;

      if ((clickedElement as ClickedElement).id === buttonId) {
        timeoutId = setTimeout(() => {
          const errorInputs = document.querySelectorAll(`[id^=${ERROR_FIELD_}]`);
          const inputFirstError = errorInputs?.length && errorInputs[0];
          if (inputFirstError) {
            inputFirstError?.scrollIntoView({ behavior: "smooth", block: "center" });
            timeoutScroll = setTimeout(() => {
              (inputFirstError as HTMLElement)?.focus();
            }, 1000);
          }
        }, 500);
      }
    };

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
      clearTimeout(timeoutId);
      clearTimeout(timeoutScroll);
    };
  }, []);
}
