import axios from "axios";

export const clientApi = axios.create({
  baseURL: import.meta.env.VITE_APP_API_URL,
  headers: {
    "Content-Type": "application/json"
  }
});

// Делаем retry запросы, если словили Network error или Network timeout
clientApi.interceptors.response.use(undefined, err => {
  const { config, message } = err;

  if (!config || !config.retry) {
    return Promise.reject(err);
  }
  if (!(message.includes("timeout") || message.includes("Network Error"))) {
    return Promise.reject(err);
  }
  config.retry -= 1;
  const delayRetryRequest = new Promise<void>(resolve => {
    setTimeout(() => {
      console.log("retry the request", config.url);
      resolve();
    }, config.retryDelay || 1000);
  });
  return delayRetryRequest.then(() => clientApi(config));
});
