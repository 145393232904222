import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { defaultAxiosRequestConfig } from "~/api/apiService";
import { HELPER_FILE } from "~/configs/apiUrls";
import { AttachmentsDetailsError } from "~/typings/types";
import { clientApi } from "~/utils";

const configRequest = {
  ...defaultAxiosRequestConfig,
  headers: {
    "Content-Type": "multipart/form-data"
  }
};

type Props = {
  passportFiles: File[];
  setPassportError: Dispatch<SetStateAction<AttachmentsDetailsError>>;
  setIsFirstLoadingFile: Dispatch<boolean>;
};

/** Используется если загрузить нужно только несколько файлов */
export const useUploadPassportFile = ({ passportFiles, setPassportError, setIsFirstLoadingFile }: Props) => {
  const [isMounted, setIsMounted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [filesId, setFilesId] = useState<number[]>([]);

  const formData = new FormData();

  useEffect(() => {
    if (isMounted) {
      try {
        if (
          !passportFiles.length ||
          !(passportFiles.length && filesId.length < passportFiles.length)
        ) {
          console.log("useUploadPassportFile остановить");
          return;
        }

        setIsLoading(true);

        const latestFile = passportFiles[passportFiles.length - 1];
        formData.append("file", latestFile);

        clientApi
          .post(HELPER_FILE, formData, configRequest)
          .then(response => {
            const fileId = response.data.data.file_id;
            setFilesId(prev => [...prev, fileId]);
            setIsFirstLoadingFile(true);
          })
          .catch(error => {
            setPassportError({
              has: 1,
              message: error.response.data.error?.[0].message
            });
          })
          .finally(() => {
            setIsLoading(false);
          });
      } catch (error) {
        console.log("useUploadPassportFile error", error);
      } finally {
      }
    } else {
      setIsMounted(true);
    }
  }, [passportFiles]);

  return { filesId, setFilesId, isLoading };
};
