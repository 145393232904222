import { RefObject } from "react";
import { Tooltip } from "antd";
import { TooltipPlacement } from "antd/es/tooltip";

import styles from "./TitleField.module.scss";

import imgQuestionActive from "./question-active.png";
import imgQuestionDefault from "./question-default.png";

type Props = {
  tooltip: string;
  hoverRef: RefObject<HTMLImageElement>;
  isHover: boolean;
  placement?: TooltipPlacement;
};

export const CustomTooltip = ({ tooltip, hoverRef, isHover, placement = 'top' }: Props) => {
  if (!tooltip) return;

  return (
    <Tooltip
      placement={placement}
      title={tooltip}
      arrow={false}
      overlayInnerStyle={{
        backgroundColor: "#8C8E94",
        padding: 30,
        fontSize: 14,
        fontFamily: "Roboto",
        lineHeight: "24px"
      }}
    >
      <img
        ref={hoverRef}
        className={styles.question}
        src={isHover ? imgQuestionActive : imgQuestionDefault}
      />
    </Tooltip>
  );
};
