// @ts-nocheck

import styles from "./TitlePage.module.scss";

export const TitlePage = ({ name, number }) => (
  <div className={styles.container}>
    <p className={styles.number}>{number}</p>
    <h2 className={styles.name}>{name}</h2>
  </div>
);
