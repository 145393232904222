import styles from "./EmailFailed.module.scss";

export const EmailFailedPage = () => {
  return (
    <div className={styles.container}>
      <div className={styles.card} style={{ marginBottom: 20 }}>
        <div className={styles.image} style={{ marginTop: 50 }} />
        <h2 className={styles.title}>Ошибка подтверждения почты</h2>
        <p className={styles.subtitle}>
          Возможно ссылка устарела или произошел сбой. Если проблема повторится, напишите письмо в
          техподдержку.
        </p>
      </div>
    </div>
  );
};
