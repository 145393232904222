import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Layout } from "~/components/layout";
import { MainPage, ErrorPage, EmailSuccessPage, EmailFailedPage } from "~/pages";
import { SentryContrainer } from "~/containers";

import "~/assets/styles/style.scss";
import "~/assets/icons/index";

export const App = (): JSX.Element => {
  return (
    <SentryContrainer>
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/email-success" element={<EmailSuccessPage />} />
            <Route path="/email-failed" element={<EmailFailedPage />} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </Layout>
      </BrowserRouter>
    </SentryContrainer>
  );
};
