export const getWithPrefixPhone = (phone?: string) => {
  if (phone && phone.length < 4) {
    return "";
  }

  if (phone) {
    if (phone[0] == "+") {
      return phone;
    } else return `+${phone}`;
  }
  return "";
};
