import React from "react";
import { TitleField } from "~/components";

import styles from "./RulesFiles.module.scss";

interface Props {
  isDocuments?: boolean;
}

export const RulesFiles = ({ isDocuments = true }: Props) => {
  return (
    <div className={styles.rulesFiles}>
      <TitleField name="Требования к файлам" styleContainer={{ marginTop: 0 }} />
      <ul className={styles.listRulesFiles}>
        {isDocuments && (
          <>
            <li>Скан должен быть сделан с оригинала</li>
            <li>
              Изображение должно быть цветное и чёткое, никакие элементы не должны быть обрезаны
            </li>
          </>
        )}
        <li>Размер файла не должен превышать 10 Мб</li>
        <li>Файл должен иметь формат PDF, JPG или PNG</li>
      </ul>
    </div>
  );
};
