import { type ReactNode } from "react";
import { Space } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { isDev } from "~/utils/isDev";

import styles from "./DevActions.module.scss";

interface Props {
  children: ReactNode;
}

export const DevActions = ({ children }: Props) => {
  if (!isDev) {
    return null;
  }
  return (
    <div className={styles.container}>
      <div className={styles.dropdown}>
        <button className={styles.dropbtn}>
          <Space>
            Действия в дев режиме
            <DownOutlined />
          </Space>
        </button>
        <div className={styles.dropdownContent}>{children}</div>
      </div>
    </div>
  );
};
