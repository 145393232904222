import { type FC } from "react";
import cn from "classnames";
import { CustomButton } from "~/components";
import { ConfigNamesFields, NamesOfFieldEnum, OrgTypeEnum } from "~/typings/types";

import styles from "./ModalContent.module.scss";
interface Props {
  fields: string | ConfigNamesFields;
  configNameFields: ConfigNamesFields;
  handleClickSuccess: () => void;
  handleClickReject: () => void;
  orgType: OrgTypeEnum;
}

export const ModalContent: FC<Props> = ({
  fields,
  handleClickSuccess,
  handleClickReject,
  configNameFields,
  orgType
}) => {
  if (typeof fields === "string") {
    return (
      <div className={styles.containerError}>
        <p className={styles.textError}>{fields}</p>
        <div className={styles.footerError}>
          <CustomButton
            name="Понятно"
            type="button"
            onClick={handleClickReject}
            className={cn(styles.button)}
          />
        </div>
      </div>
    );
  }

  const renderName = (name: undefined | string) => {
    if (!name) {
      return null;
    }
    return (
      <h4 style={{ fontWeight: "bold" }}>
        {orgType === OrgTypeEnum.IndividualEntrepreneur
          ? `${name}, это Вы?`
          : `${name}, это Ваша организация?`}
      </h4>
    );
  };

  const renderContent = (fields: ConfigNamesFields) => {
    return (Object.entries(fields) as [NamesOfFieldEnum, string][]).map(
      ([nameField, valueField]) => {
        if (nameField === "name" || !(nameField in configNameFields)) {
          return;
        }
        return (
          <div key={nameField} className={styles.fieldsRow}>
            <p className={styles.firstColumn}>{configNameFields[nameField]}</p>
            <p className={styles.secondColumn}>{valueField}</p>
          </div>
        );
      }
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div>{renderName(fields?.name)}</div>
        <div>{renderContent(fields)}</div>
      </div>
      <div className={styles.footer}>
        <CustomButton
          name="Да, правильно"
          type="button"
          onClick={handleClickSuccess}
          className={cn(styles.button)}
        />
        <CustomButton
          name="Нет"
          type="button"
          onClick={handleClickReject}
          className={cn(styles.button, styles.buttonBack)}
        />
      </div>
    </div>
  );
};
