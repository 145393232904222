import { useState } from "react";
import { postRequestCode } from "~/api/apiMethods";
import { INITIAL_REQUEST_CODE, SEND_WITHOUT_REQUEST_CODE } from "~/configs/consts";
import { ClientId, StatusConfirmationCode } from "~/typings/types";
import { useTimer } from "~/hooks";

const COUNT_TIMER = 60; // задержка для повтороного запроса кода
export const COUNT_REQUEST_CODE = 3; // кол-во попыток запроса кода

type Props = {
  clientId: ClientId;
};

export const useConfirmationCode = ({ clientId }: Props) => {
  const [statusConfirmationCode, setStatusConfirmationCode] =
    useState<StatusConfirmationCode>(INITIAL_REQUEST_CODE);
  const [isLoading, setIsLoading] = useState(false);
  const [errorOfCode, setErrorOfCode] = useState<false | string>(false);
  const [valueOfCode, setValueOfCode] = useState("");
  const [codeUUID, setCodeUUID] = useState<null | string>(null);
  const [errorManyAttempts, setErrorManyAttempts] = useState<false | string>(false);

  const {
    isRunning: isRunningTimer,
    start: startTimer,
    stop: stopTimer,
    seconds: secondsTimer,
    countTryStart: countTryStartTimer,
    setCountTryStart: setCountTryStartTimer
  } = useTimer({ duration: COUNT_TIMER, setStatusConfirmationCode });

  const handleGetRequestCode = async ({ status }: { status: StatusConfirmationCode }) => {
    try {
      setIsLoading(true);
      const responseRequestCode = await postRequestCode({
        data: {
          id: clientId
        }
      });
      startTimer();
      setIsLoading(false);
      if (responseRequestCode.data?.code_uuid) {
        setCodeUUID(responseRequestCode.data?.code_uuid);
        setStatusConfirmationCode(status);
      } else {
        //TODO: need add handler error
        console.log("Ошибка при попытке отправить СМС на номер телефона или ошибка бека");
      }
    } catch (error: any) {
      if (error?.response?.data?.errors?.[0]?.code === "code_confirmation:too_many_attempts") {
        setErrorManyAttempts(error?.response?.data?.errors?.[0]?.message);
        setIsLoading(false);
        setStatusConfirmationCode(SEND_WITHOUT_REQUEST_CODE);
      }
    }
  };

  return {
    statusConfirmationCode,
    handleGetRequestCode,
    errorOfCode,
    setErrorOfCode,
    valueOfCode,
    setValueOfCode,
    codeUUID,
    isLoading,
    isRunningTimer,
    secondsTimer,
    stopTimer,
    countTryStartTimer,
    setCountTryStartTimer,
    errorManyAttempts,
    setErrorManyAttempts,
    setStatusConfirmationCode
  };
};
