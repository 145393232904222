import { Dispatch, useState } from "react";
import { GET_BANK_INFO } from "~/configs/apiUrls";
import { TypeBankInfo } from "~/typings/types";
import { clientApi } from "~/utils";
import { ErrorRequest } from "../FormFieldAutocompleteBic";

export const useGetBankInfo = (setErrorGetSuggestions: Dispatch<ErrorRequest>) => {
  const [suggestions, setSuggestions] = useState<TypeBankInfo[]>([]);
  const [isPendingResponseSuggestions, setIsPendingResponseSuggestions] = useState(false);

  const fetchSuggestions = async (query: string) => {
    try {
      setIsPendingResponseSuggestions(true);
      setErrorGetSuggestions(null);

      const response = await clientApi.get(`${GET_BANK_INFO}?keyword=${query}`);
      setSuggestions(response.data.data);
    } catch (error: any) {
      setErrorGetSuggestions(error?.response?.data?.error?.[0]);
    } finally {
      setIsPendingResponseSuggestions(false);
    }
  };

  return {
    suggestions,
    setSuggestions,
    isPendingResponseSuggestions,
    fetchSuggestions
  };
};
