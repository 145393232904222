import { ReactNode } from "react";
import * as Sentry from "@sentry/react";
import { ErrorPage } from "~/pages";
import { getClientIdFromStorage } from "~/utils/getClientIdStorage";

if (
  (import.meta.env.VITE_APP_ENV === "preproduction" || import.meta.env.VITE_APP_ENV === "production") &&
  import.meta.env.VITE_APP_SENTRY_RELEASE
) {
  console.log("sentry init");
  Sentry.init({
    dsn: import.meta.env.VITE_APP_SENTRY_DSN,
    release: import.meta.env.VITE_APP_SENTRY_RELEASE,
    environment: import.meta.env.VITE_APP_ENV,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  });

  Sentry.configureScope(function (scope) {
    scope.setExtras({
      clientId: getClientIdFromStorage() || "ID клиента не нашлось, надо поправить тут реализацию"
    });
  });
}

interface Props {
  children: ReactNode;
}

export const SentryContrainer = ({ children }: Props) => (
  <Sentry.ErrorBoundary fallback={<ErrorPage />}>{children}</Sentry.ErrorBoundary>
);
