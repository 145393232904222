// @ts-nocheck
import PhoneInput, { CountryData } from "react-phone-input-2";
import ru from "react-phone-input-2/lang/ru.json";
import "react-phone-input-2/lib/style.css";
import "./style.css";
import { useState } from "react";

export const PhoneField = ({
  input,
  submitError,
  submitFailed,
  error,
  isAutoFocus,
  orderNumber,
  disabled,
  onlyCountries,
  ...props
}: any) => {
  const [countryInfo, setCountryInfo] = useState<CountryData>("");
  const hasError = (error || submitError) && submitFailed;

  const handleCountryChange = (value: string, info: CountryData) => {
    setCountryInfo(info);
    input.onChange(value);
  };

  return (
    <div>
      <PhoneInput
        {...props}
        disabled={disabled}
        value={input?.value}
        onChange={handleCountryChange}
        localization={ru}
        onlyCountries={onlyCountries}
        country="ru"
        masks={{ ci: ".........." }}
        specialLabel={""}
        areaCodes={{ kz: [7, 6] }}
        inputProps={{
          name: input.name,
          autoFocus: isAutoFocus
        }}
        inputStyle={{
          width: "100%",
          height: 32,
          border: "none",
          color: hasError ? "#E41D36" : "#000",
          backgroundColor: disabled ? "rgba(0, 0, 0, 0.04)" : "white"
        }}
        containerStyle={{
          border: hasError ? "1px solid #E41D36" : "1px solid rgb(230, 230, 230)",
          borderRadius: "6px",
          borderBottomLeftRadius: 3,
          borderTopLeftRadius: 3
        }}
      />
    </div>
  );
};
