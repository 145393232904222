// @ts-nocheck
import { Field } from "react-final-form";
import { RadioField, TitlePage } from "~/components";
import { composeValidators, disabledValidate, required } from "~/utils/validate";
import { Divider } from "antd";
import { Wizard, FormField } from "~/pages/main/steps/shared";
import { NamesOfFieldEnum } from "~/typings/types";
import { configTitleFieldsOfCompany } from "../configTitleFields";

import styles from "../../shared/Shared.module.scss";

export const AdditionalDetails = ({ queryPromocode }) => {
  return (
    <Wizard.Page>
      <TitlePage name="Укажите дополнительные сведения" number="8" />
      <Divider style={{ margin: 0 }} />
      <div className={styles.container}>
        <FormField
          titleField={configTitleFieldsOfCompany[NamesOfFieldEnum.ClientFrom]}
          name={NamesOfFieldEnum.ClientFrom}
          required
          validate={composeValidators(required())}
          orderNumber="1"
        />

        <FormField
          titleField={configTitleFieldsOfCompany[NamesOfFieldEnum.ClientPromocode]}
          name={NamesOfFieldEnum.ClientPromocode}
          disabled={queryPromocode}
          orderNumber="2"
        />

        <FormField
          titleField={configTitleFieldsOfCompany[NamesOfFieldEnum.ClientLengthOfService]}
          name={NamesOfFieldEnum.ClientLengthOfService}
          required
        >
          <div className={styles.containerRadio}>
            <Field
              name={NamesOfFieldEnum.ClientLengthOfService}
              type="radio"
              value="first_run"
              component={props => <RadioField orderNumber="3" {...props} />}
              validate={disabledValidate(composeValidators(required()))}
            >
              Первый запуск
            </Field>
            <Field
              name={NamesOfFieldEnum.ClientLengthOfService}
              type="radio"
              value="lower_than_year"
              component={props => <RadioField orderNumber="3" {...props} />}
              validate={disabledValidate(composeValidators(required()))}
            >
              Менее одного года
            </Field>
            <Field
              name={NamesOfFieldEnum.ClientLengthOfService}
              type="radio"
              value="greater_than_year"
              component={props => <RadioField orderNumber="3" {...props} />}
              validate={disabledValidate(composeValidators(required()))}
            >
              Более одного года
            </Field>
          </div>
        </FormField>
      </div>
    </Wizard.Page>
  );
};
