import { Wizard } from "./wizard";

/**
 * - Компонент нужен для корректной работы переключения шагов
 * (без него бага при клике на кнопку "Назад");
 * - На UI его никак не видно, нужен только для
 * правильной работы бизнес логики переключения степов
 * @returns JSX.Element
 */
export const DefaultPage = () => <Wizard.Page>Default page</Wizard.Page>;
