import { TitlePage } from "~/components";
import { Divider } from "antd";
import { Wizard, Confirmation, PropsConfirmation } from "~/pages/main/steps/shared";

export const ConfirmationCode = (props: PropsConfirmation) => {
  return (
    <Wizard.Page>
      <TitlePage name="Подтверждение телефона" number="10" />
      <Divider style={{ margin: 0 }} />
      <Confirmation {...props} />
    </Wizard.Page>
  );
};
