export const formatDate = (initDate: string) => {
  const date = new Date(initDate);
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit"
  };
  const formattedDate = date.toLocaleDateString("ru-RU", options);
  return formattedDate;
};
