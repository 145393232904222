import styles from "./EmailSuccess.module.scss";

export const EmailSuccessPage = () => {
  return (
    <div className={styles.container}>
      <div className={styles.card} style={{ marginBottom: 20 }}>
        <div className={styles.image} style={{ marginTop: 50 }} />
        <h2 className={styles.title}>Почта подтверждена</h2>
        <p className={styles.subtitle}>
          Спасибо, теперь в случае чего, Вы всегда сможете восстановить доступ по email.
        </p>
      </div>
    </div>
  );
};
