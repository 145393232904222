import React from "react";

import { Header } from "../header";
import { Footer } from "../footer";

import styles from "./Layout.module.scss";

interface Props {
  children: React.ReactNode;
}

export const Layout = ({ children }: Props): JSX.Element => {
  return (
    <div className={styles.container}>
      <Header />
      {children}
      <Footer />
    </div>
  );
};
