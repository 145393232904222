// @ts-nocheck
import { ReactNode, useEffect } from "react";
import { Input, InputNumber } from "antd";
import cn from "classnames";
import { ERROR_FIELD_ } from "~/configs/consts";
import { TypeInput } from "~/typings/types";
import { useDebounce } from "usehooks-ts";

import styles from "./InputField.module.scss";

export const InputField = ({
  meta,
  placeholder,
  typeInput = "input",
  autoSize = false,
  maxLength,
  showCount = false,
  addonAfter,
  addonBefore,
  orderNumber,
  mask = "",
  typeRequestCheckPayformName,
  onBlur,
  ...input
}) => {
  const isLoadingCallbackRequest = typeRequestCheckPayformName === "loading";
  const isErrorCallbackRequest = typeRequestCheckPayformName === "error" || typeRequestCheckPayformName === "errorValidateFromApi";
  const isSuccessCallbackRequest = typeRequestCheckPayformName === "success";

  const hasError = isSuccessCallbackRequest
    ? false
    : isErrorCallbackRequest || ((meta?.error || meta?.submitError) && meta?.submitFailed);
  const inputId = hasError ? `${ERROR_FIELD_ + orderNumber}` : undefined;

  const debouncedValue = onBlur && useDebounce<string>(input?.value, 2000);

  const stylesInput = {
    ...input?.style,
    borderColor: hasError ? "#E41D36" : "#E6E6E6",
    color: hasError ? "#E41D36" : "#000",
    width: "100%"
  };

  const sharedComponentProps = {
    className: styles.input,
    style: { ...stylesInput },
    placeholder,
    maxLength,
    id: inputId,
    ...input
  };

  const configComponents: Record<TypeInput, ReactNode> = {
    textArea: (
      <Input.TextArea autoSize={autoSize} showCount={showCount} {...sharedComponentProps} />
    ),
    inputNumber: <InputNumber controls={false} {...sharedComponentProps} />,
    input: <Input addonAfter={addonAfter} addonBefore={addonBefore} {...sharedComponentProps} />
  };

  //  --- логика работы маски в интпуте ---
  useEffect(() => {
    if (
      mask &&
      ((input.value && meta.active && meta.pristine) ||
        (input.value && meta.active && input.value.length <= mask.length))
    ) {
      if (input.value.substr(0, mask.length) === mask) {
        input.onChange(input.value);
        return;
      }
      if (input.value === mask.substr(0, mask.length - 1)) {
        input.onChange(mask);
        return;
      }
      input.onChange(mask + input.value);
    }
  }, [input, meta]);
  //  --- end ---

  useEffect(() => {
    onBlur && debouncedValue && onBlur(debouncedValue);
  }, [debouncedValue]);

  return (
    <div
      className={cn({
        [styles.inputWrapperError]: hasError && !isLoadingCallbackRequest
      })}
    >
      {configComponents[typeInput]}
    </div>
  );
};
