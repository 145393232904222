import { postDraftSubmitSave } from "~/api/apiMethods";

interface OnSubmitForm {
  clientId: string;
}

export const onSubmitForm = async ({ clientId }: OnSubmitForm): Promise<void> => {
  return new Promise((resolve, reject) => {
    const data = {
      id: clientId
    };
    postDraftSubmitSave({
      data,
      config: {}
    })
      .then(() => {
        resolve(undefined);
      })
      .catch(err => {
        reject(err);
      });
  });
};
