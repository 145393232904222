import { SVGProps } from "react";

enum IconSize {
  xs = 14,
  sm = 18,
  md = 24,
  lg = 30,
  xl = 36,
  auto = "auto",
}

type TIconSize = keyof typeof IconSize;

type IconProps = {
  width?: number;
  height?: number;
  name: string;
  size?: TIconSize;
  interactive?: boolean;
  className?: string;
} & SVGProps<SVGSVGElement>;

export const Icon = ({
  width = 18,
  height = 18,
  name,
  fill = "CurrentColor",
  size = "auto",
  interactive = false,
  ...svgProps
}: IconProps) => {
  const iconWidth = size ? IconSize[size] : width;
  const iconHeight = size ? IconSize[size] : height;

  if (size === "auto") {
    return (
      <svg {...svgProps}>
        <use xlinkHref={`#${name}`} fill={fill} />
      </svg>
    );
  }
  return (
    <svg {...svgProps} width={`${iconWidth}px`} height={`${iconHeight}px`}>
      <use xlinkHref={`#${name}`} fill={fill} />
    </svg>
  );
};
