import { useRef } from "react";
import cn from "classnames";
import { useHover } from "~/hooks/useHover";
import { CustomTooltip } from "./CustomTooltip";

import styles from "./TitleField.module.scss";

export const TitleField = ({
  name,
  required = false,
  tooltip = "",
  isColon = true,
  styleContainer,
  placement
}: any) => {
  const hoverRef = useRef<HTMLImageElement>(null);
  const isHover = useHover<HTMLImageElement>(hoverRef);

  const nameHtml = `${name}${isColon ? ":" : ""}`;

  return (
    <div className={styles.container} style={styleContainer}>
      <h3
        dangerouslySetInnerHTML={{ __html: nameHtml }}
        className={cn({ [styles.text]: required })}
      />
      <CustomTooltip 
        tooltip={tooltip}
        hoverRef={hoverRef}
        isHover={isHover}
        placement={placement}
      />
    </div>
  );
};
