import React, { ReactNode } from "react";
import ReactDOM from "react-dom";
import { useOnClickOutside } from "usehooks-ts";
import { ModalToggle } from "~/hooks/useModal";

import styles from "./Modal.module.scss";

interface Props {
  isShow: boolean;
  toggle: ModalToggle;
  children?: ReactNode;
  isOutsideClick?: boolean; // Закрыть модальное окно при клике вне окна
}

export const Modal = ({ isShow, toggle, isOutsideClick = false, children }: Props) => {
  const ref = React.useRef<HTMLInputElement>(null);

  const handleClickOutside = () => {
    toggle();
  };

  isOutsideClick && useOnClickOutside(ref, handleClickOutside);

  return isShow
    ? ReactDOM.createPortal(
        <React.Fragment>
          <div className={styles.modalOverlay} />
          <div className={styles.modal} aria-modal aria-hidden tabIndex={-1} role="dialog">
            <div className={styles.container} ref={ref}>
              {children}
            </div>
          </div>
        </React.Fragment>,
        document.body
      )
    : null;
};
