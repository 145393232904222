import { useState } from "react";

export type ModalToggle = () => void;

export const useModal = () => {
  const [isShow, setIsShow] = useState(false);

  const open: ModalToggle = () => {
    setIsShow(true);
  };

  const toggle: ModalToggle = () => {
    setIsShow(!isShow);
  };

  const close: ModalToggle = () => {
    setIsShow(false);
  };

  return {
    isShow,
    open,
    toggle,
    close
  };
};
