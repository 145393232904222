// @ts-nocheck
import { Field } from "react-final-form";
import { TitlePage, PhoneField } from "~/components";
import { Divider } from "antd";
import { Wizard, FormField } from "~/pages/main/steps/shared";
import { NamesOfFieldEnum } from "~/typings/types";
import { configTitleFieldsOfSelfEmployed } from "../configTitleFields";

import styles from "../../shared/Shared.module.scss";

export const ContactDetails = () => {
  return (
    <Wizard.Page>
      <TitlePage name="Укажите ваши контактные данные" number="4" />
      <Divider style={{ margin: 0 }} />
      <div className={styles.container}>
        <FormField
          titleField={configTitleFieldsOfSelfEmployed[NamesOfFieldEnum.LprName]}
          name={NamesOfFieldEnum.LprName}
          tooltip="Если у Вас есть контактное лицо, которое будет принимать решения по работе проекта, то просим указать его контактные данные"
          orderNumber="1"
          triggerAutofill={[NamesOfFieldEnum.LprName, NamesOfFieldEnum.CrmName]}
        />

        <FormField
          titleField={configTitleFieldsOfSelfEmployed[NamesOfFieldEnum.LprPhone]}
          name={NamesOfFieldEnum.LprPhone}
          triggerAutofill={[NamesOfFieldEnum.LprPhone, NamesOfFieldEnum.CrmPhone]}
        >
          <Field
            name={NamesOfFieldEnum.LprPhone}
            subscription={{ submitFailed: true, error: true, submitError: true, value: true }}
            component={props => <PhoneField orderNumber="2" {...props} />}
          />
        </FormField>

        <FormField
          titleField={configTitleFieldsOfSelfEmployed[NamesOfFieldEnum.BuhName]}
          name={NamesOfFieldEnum.BuhName}
          tooltip="Если у Вас есть контактное лицо, которое будет принимать решения по финансовым вопросам
							 (приём платежей, возвраты, сроки вывода, размер комиссии и т. д.), то просим указать его контактные данные"
          orderNumber="3"
          triggerAutofill={[NamesOfFieldEnum.BuhName, NamesOfFieldEnum.CrmName]}
        />

        <FormField
          titleField={configTitleFieldsOfSelfEmployed[NamesOfFieldEnum.BuhPhone]}
          name={NamesOfFieldEnum.BuhPhone}
          triggerAutofill={[NamesOfFieldEnum.BuhPhone, NamesOfFieldEnum.CrmPhone]}
        >
          <Field
            name={NamesOfFieldEnum.BuhPhone}
            subscription={{ submitFailed: true, error: true, submitError: true, value: true }}
            component={props => <PhoneField orderNumber="4" {...props} />}
          />
        </FormField>

        <FormField
          titleField={configTitleFieldsOfSelfEmployed[NamesOfFieldEnum.TechName]}
          name={NamesOfFieldEnum.TechName}
          tooltip="Если у Вас есть контактное лицо, которое будет принимать решения по техническим вопросам, то просим указать его контактные данные"
          orderNumber="5"
          triggerAutofill={[NamesOfFieldEnum.TechName, NamesOfFieldEnum.CrmName]}
        />

        <FormField
          titleField={configTitleFieldsOfSelfEmployed[NamesOfFieldEnum.TechPhone]}
          name={NamesOfFieldEnum.TechPhone}
          triggerAutofill={[NamesOfFieldEnum.TechPhone, NamesOfFieldEnum.CrmPhone]}
        >
          <Field
            name={NamesOfFieldEnum.TechPhone}
            subscription={{ submitFailed: true, error: true, submitError: true, value: true }}
            component={props => <PhoneField orderNumber="6" {...props} />}
          />
        </FormField>
      </div>
    </Wizard.Page>
  );
};
